import { useTranslation } from 'react-i18next'
import { useAuth } from './useAuth'
import useConnectivityStatus from './useConnectivityStatus'
import { APP_ROUTES } from '../routes/routes'
import PlayerStatusIcon from '../components/icons/PlayerStatusIcon'
import PlayerBlockIcon from '../components/icons/PlayerBlockIcon'
import SystemInformationIcon from '../components/icons/SystemInformationIcon'
import SocialConceptIcon from '../components/icons/SocialConceptIcon'

export const useMenuItems = () => {
  const { t } = useTranslation('oasisApp')
  const { selectedShop } = useAuth()
  const isOasisAvailable = useConnectivityStatus()
  const isShopLocked = selectedShop?.scheduledLock?.active

  // Base availability check for most menu items
  const isBaseAvailable = isOasisAvailable && !isShopLocked

  const menuItems = [
    {
      id: 'playerStatus',
      image: <PlayerStatusIcon />,
      label: t('menu.playerStatus'),
      href: APP_ROUTES.APP.MENU.PLAYER_STATUS,
      isDisabled: !isBaseAvailable
    },
    {
      id: 'playerBlock',
      image: <PlayerBlockIcon />,
      label: t('menu.playerBlock'),
      href: APP_ROUTES.APP.MENU.PLAYER_BLOCK,
      isDisabled: !isBaseAvailable
    }
  ]

  // Only add social concept menu item if it's enabled for the shop
  if (!!selectedShop?.socialConceptEnabled) {
    menuItems.push({
      id: 'socialConcept',
      image: <SocialConceptIcon />,
      label: t('menu.socialConcept'),
      href: APP_ROUTES.APP.MENU.SOCIAL_CONCEPT,
      isDisabled: false
    })
  }

  // Add system info as the last item
  menuItems.push({
    id: 'systemInfo',
    image: <SystemInformationIcon />,
    label: t('menu.systemInformation'),
    href: APP_ROUTES.APP.MENU.SYSTEM_INFO,
    isDisabled: false // Always enabled
  })

  return menuItems
}
