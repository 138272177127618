export const APP_ROUTES = {
  ROOT: '/',
  ACTIVATE_ACCOUNT: '/activateaccount',
  NOT_ACTIVATED: '/notactivated',
  APP: {
    ROOT: '/app',
    SHOP_SELECTION: '/app/shopselection',
    NO_VALID_LICENSE: '/app/novalidlicense',
    SHOP_LOCKED: '/app/shoplocked',
    MENU: {
      PLAYER_STATUS: '/app/playerstatus',
      PLAYER_BLOCK: '/app/playerblock/ocr',
      SOCIAL_CONCEPT: '/app/socialconcept',
      SYSTEM_INFO: '/app/systeminformation'
    }
  }
}
