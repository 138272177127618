import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { PaddingFooter } from './Common'
import OasisButton from './OasisButton'
import OasisTextField from './OasisTextField'
import { appVersion } from '../services/consts'
import MetadataContext from '../hooks/useMetadataContext'

const SystemInformation = () => {
  const { t } = useTranslation('oasisApp')
  const navigate = useNavigate()
  const { user, selectedShop } = useAuth()
  const { types, locations } = useContext(MetadataContext)

  return <Grid
    container
    direction='column'
    justifyContent='center'
    alignItems='center'
    spacing={0}
  >
    <Grid
      item
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      xs={10}
    >
      <Typography variant='h6' color='primary'>
        {t('systemInformation.header')}
      </Typography>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          fullWidth
          label={t('systemInformation.userEmail')}
          value={user.email}
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          fullWidth
          label={t('systemInformation.shopName')}
          value={selectedShop.name}
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          fullWidth
          label={t('systemInformation.shopType')}
          value={types[selectedShop.type].label || ''}
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          fullWidth
          label={t('systemInformation.shopLocation')}
          value={locations[selectedShop.location]?.label || ''}
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          fullWidth
          label={t('systemInformation.schedule')}
          value={selectedShop.scheduleName}
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          fullWidth
          label={t('systemInformation.socialConcept')}
          value={selectedShop.socialConceptEnabled ? t('common.enabled') : t('common.disabled')}
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          fullWidth
          label={t('systemInformation.shopEmail')}
          value={selectedShop.email}
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          fullWidth
          label={t('systemInformation.ownerName')}
          value={user.owner.name}
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          multiline
          minRows={3}
          maxRows={3}
          fullWidth
          label={t('systemInformation.ownerPhoneNumber')}
          value={user.owner.contactInfo || ''}
        />
      </FormControl>
    </Grid>
    <Grid
      item
      container
      xs={10}
    >
      {/*TODO TRANSLATION*/}
      <FormControl fullWidth>
        <OasisTextField
          InputProps={{
            readOnly: true
          }}
          fullWidth
          label={t('systemInformation.appVersion')}
          value={appVersion}
        />
      </FormControl>
    </Grid>
    <PaddingFooter
      item
      container
      xs={10}>
      <OasisButton onClick={() => navigate('/app')}>
        {t('common.ok')}
      </OasisButton>
    </PaddingFooter>
  </Grid>
}

SystemInformation.defaultProps = {
  label: 'Systeminformationen'
}

export default SystemInformation
