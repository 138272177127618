import { Grid } from '@material-ui/core'
import React from 'react'
import { useAuth } from '../../hooks/useAuth'
import ScheduledLockInfo from './ScheduledLockInfo'
import { useTranslation } from 'react-i18next'
import MenuButton from './MenuButton'
import { useMenuItems } from '../../hooks/useMenuItems'

function MenuScreen() {
  const { t } = useTranslation('oasisApp')
  const { selectedShop } = useAuth()
  const { scheduledLock } = selectedShop
  const menuItems = useMenuItems()

  const scheduledLockTime = {
    start: scheduledLock?.startTime,
    end: scheduledLock?.endTime
  }

  return (
    <Grid
      className="menu"
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      {(scheduledLock?.active && scheduledLock?.reason) && (
        <ScheduledLockInfo>
          {t(`scheduleReason.${scheduledLock.reason}`, scheduledLockTime)}
        </ScheduledLockInfo>
      )}
      
      {menuItems.map(({ id, href, isDisabled, ...menuProps }) => (
        <Grid container item className="menu__item" xs={10} key={id}>
          <MenuButton
            disabled={isDisabled}
            to={isDisabled ? '#' : href}
            {...menuProps}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default MenuScreen
