import React, { useEffect, useRef, useState } from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getSocialConcept } from '../../services/api'
import logger from '../../logger'
import { Document, Page } from 'react-pdf'
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@material-ui/icons'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
// This is need to properly load the worker from the webpack bundle
// eslint-disable-next-line no-unused-vars
import * as pdfjsLib from 'pdfjs-dist/webpack'

const containerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden'
}

const navigationStyle = {
  alignItems: 'center',
  backgroundColor: '#eeeeee',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'center',
  padding: '8px',
  gap: '4px'
}

const buttonContainerStyle = {
  padding: '0px 4px'
}

const pageStyle = {
  maxWidth: '100%',
  maxHeight: 'calc(100vh - 120px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'auto'
}

const SocialConcept = () => {
  const { t } = useTranslation('oasisApp')
  const containerRef = useRef(null)
  const [pdfData, setPdfData] = useState(null)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [containerWidth, setContainerWidth] = useState(null)

  useEffect(() => {
    const loadPDF = async () => {
      try {
        logger.debug('Fetching social concept PDF')
        const pdfBuffer = await getSocialConcept()
        logger.debug(`PDF buffer received: ${pdfBuffer.byteLength} bytes`)
        setPdfData(pdfBuffer)
      } catch (err) {
        logger.error(`Failed to fetch social concept: ${err.message}`)
        setError(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    loadPDF()
  }, [])

  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        const width = containerRef.current.clientWidth
        logger.debug(`Container width updated to ${width}px`)
        setContainerWidth(width)
      }
    }

    updateContainerWidth()
    window.addEventListener('resize', updateContainerWidth)

    return () => window.removeEventListener('resize', updateContainerWidth)
  }, [])

  const onDocumentLoadSuccess = ({ numPages }) => {
    logger.debug(`PDF document loaded with ${numPages} pages`)
    setNumPages(numPages)
    setPageNumber(1)
  }

  const onDocumentLoadProgress = ({ loaded, total }) => {
    logger.debug(`Loading PDF: ${Math.round((loaded / total) * 100)}%`)
  }

  const onPageLoadSuccess = () => {
    logger.debug(`Page ${pageNumber} loaded successfully`)
  }

  const onPageLoadError = (error) => {
    logger.error(`Error loading page ${pageNumber}: ${error.message}`)
  }

  const goToFirstPage = () => setPageNumber(1)
  const goToPreviousPage = () => setPageNumber((prev) => Math.max(prev - 1, 1))
  const goToNextPage = () => setPageNumber((prev) => Math.min(prev + 1, numPages))
  const goToLastPage = () => setPageNumber(numPages)

  if (isLoading) {
    return (
      <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '50vh' }}>
        <Typography variant='h6' color='primary'>
          {t('common.loading')}
        </Typography>
      </Grid>
    )
  }

  if (error) {
    return (
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={2}
        style={{ minHeight: '50vh' }}
      >
        <Grid item>
          <Typography variant='h6' align='center'>
            {error === 'No social concept available for this shop'
              ? t('socialConcept.notAvailable.title')
              : t('common.error')}
          </Typography>
        </Grid>
        {error === 'No social concept available for this shop' && (
          <>
            <Grid item>
              <Typography variant='body1' align='center'>
                {t('socialConcept.notAvailable.description')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1' align='center'>
                <a href='https://www.ardanto.de' target='_blank' rel='noopener noreferrer'>
                  www.ardanto.de
                </a>
              </Typography>
            </Grid>
          </>
        )}
        {error !== 'No social concept available for this shop' && (
          <Grid item>
            <Typography variant='body2' color='error'>
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Grid container direction='column' style={{ height: 'calc(100vh - 64px)' }}>
      <Grid item>
        <div style={navigationStyle}>
          <div style={buttonContainerStyle}>
            <IconButton onClick={goToFirstPage} disabled={pageNumber <= 1}>
              <FirstPage />
            </IconButton>
          </div>
          <div style={buttonContainerStyle}>
            <IconButton onClick={goToPreviousPage} disabled={pageNumber <= 1}>
              <ChevronLeft />
            </IconButton>
          </div>
          <div style={buttonContainerStyle}>
            <Typography>
              {t('common.page', {
                current: pageNumber,
                total: numPages || 0
              })}
            </Typography>
          </div>
          <div style={buttonContainerStyle}>
            <IconButton onClick={goToNextPage} disabled={pageNumber >= numPages}>
              <ChevronRight />
            </IconButton>
          </div>
          <div style={buttonContainerStyle}>
            <IconButton onClick={goToLastPage} disabled={pageNumber >= numPages}>
              <LastPage />
            </IconButton>
          </div>
        </div>
      </Grid>

      <Grid item style={containerStyle}>
        <div ref={containerRef} style={pageStyle}>
          <Document
            file={pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadProgress={onDocumentLoadProgress}
            onLoadError={(error) => {
              logger.error(`Error loading document: ${error.message}`)
              setError(error.message)
            }}
            onItemClick={({ pageNumber: itemPageNumber }) => {
              logger.debug(`Internal link clicked to page ${itemPageNumber}`)
              setPageNumber(itemPageNumber)
            }}
            loading={
              <Grid container justifyContent='center' alignItems='center'>
                <Typography>{t('common.loading')}</Typography>
              </Grid>
            }
          >
            <Page
              pageNumber={pageNumber}
              renderTextLayer={true}
              renderAnnotationLayer={true}
              width={containerWidth ? containerWidth - 32 : undefined}
              onLoadSuccess={onPageLoadSuccess}
              onLoadError={onPageLoadError}
              loading={
                <Grid container justifyContent='center' alignItems='center'>
                  <Typography>{t('common.loading')}</Typography>
                </Grid>
              }
            />
          </Document>
        </div>
      </Grid>
    </Grid>
  )
}

export default SocialConcept
