import React from 'react'

const SocialConceptIcon = () => (
  <svg width='75' height='46' viewBox='0 0 75 46' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 2C0 0.895431 0.895431 0 2 0H73C74.1046 0 75 0.895431 75 2V44C75 45.1046 74.1046 46 73 46H2C0.895431 46 0 45.1046 0 44V2ZM6 12H69V15H6V12ZM69 21H6V24H69V21ZM6 30H69V33H69.5H6V30Z'
      fill='currentColor'
    />
  </svg>
)

export default SocialConceptIcon
