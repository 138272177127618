import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import { formatDate, validateAge } from '../helpers'
import OasisButton from './../OasisButton'
import { checkPlayerStatus } from '../../services/api'
import Typography from '@material-ui/core/Typography'
import { PaddingFooter } from '../Common'
import { useTranslation } from 'react-i18next'
import OasisDatePicker from './../OasisDatePicker'
import OasisFormControlWithVal from '../playerblock/OasisFormControlWithVal'
import { useForm } from 'react-hook-form'
import logger from '../../logger'
import { subYears } from 'date-fns'
import { defaultBirthDatePlayerStatus } from '../consts'

const IdFormScreen = ({ firstName, lastName, dateOfBirth, placeOfBirth, onPlayerStatus }) => {
  const { control, handleSubmit, formState: { errors }, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange'
  })
  const { t } = useTranslation('oasisApp')
  const [name, setName] = useState(firstName)
  const [surname, setSurname] = useState(lastName)
  const [birthDate, setBirthDate] = useState(new Date(dateOfBirth))
  const [birthPlace, setBirthPlace] = useState(placeOfBirth)
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    checkPlayerStatus({ name, surname, birthDate: formatDate(birthDate), birthPlace })
      .then((response) => {
        logger.info(response)
        onPlayerStatus(response)
        setLoading(false)
      }, () => setLoading(false))
      .catch((err) => logger.error(err))
  }

  const isAdult = validateAge(birthDate)
  const buttonDisabled = () => !(formState.isValid && isAdult)

  !isAdult && logger.debug(`Field: birthDate filled with: ${birthDate} isNotAdult`)

  return <form
    onSubmit={handleSubmit(onSubmit)}
    style={{ width: '100%' }}
  >
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      spacing={1}
    >
      <Grid
        item
        xs={10}
      >
        <Typography
          variant='h6'
          color='primary'
        >
          {t('playerStatus.playerStatusInfo')}
        </Typography>
      </Grid>
      <OasisFormControlWithVal
        name='firstName'
        control={control}
        value={name}
        onInput={setName}
        errors={errors.firstName}
        label={t('common.firstName')}
        errorsHint={t('errors.fieldRequired')}
      />
      <OasisFormControlWithVal
        name='lastName'
        control={control}
        value={surname}
        onInput={setSurname}
        errors={errors.lastName}
        label={t('common.lastName')}
        errorsHint={t('errors.fieldRequired')}
      />
      <Grid
        item
        container
        xs={10}
      >
        <FormControl fullWidth>
          <OasisDatePicker
            label={t('common.birthday')}
            onChange={setBirthDate}
            value={birthDate}
            error={!isAdult}
            helperText={!isAdult && t('errors.playerTooYoung')}
            maxDate={subYears(new Date(), 18)}
          />
        </FormControl>
      </Grid>
      <OasisFormControlWithVal
        name='placeOfBirth'
        control={control}
        value={birthPlace}
        onInput={setBirthPlace}
        errors={errors.placeOfBirth}
        label={t('common.placeOfBirth')}
        rules={{ required: false }}
      />
      <PaddingFooter
        container
        item
        xs={10}
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <OasisButton
          fullWidth
          type='submit'
          disabled={buttonDisabled()}
          loading={loading}
        >
          {t('playerStatus.carryOutQuery')}
        </OasisButton>
      </PaddingFooter>
    </Grid>
  </form>
}

IdFormScreen.propTypes = {
  onLogin: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  placeOfBirth: PropTypes.string
}
IdFormScreen.defaultProps = {
  firstName: '',
  lastName: '',
  dateOfBirth: defaultBirthDatePlayerStatus
}

export default IdFormScreen
