import fetchFromAPI from './fetchFromAPI'
import { createDefaultHeaders } from './request'
import { rootURL } from './consts'

export const activateAccount = (token) => fetchFromAPI(
  'app/activateAccount',
  {
    method: 'POST',
    body: JSON.stringify({ token })
  }
)

export const getConnectivityStatus = () => fetchFromAPI('app/api/connectivity')

export const checkPlayerStatus = ({ name, surname, birthDate, birthPlace }) => fetchFromAPI(
  'app/api/spielerstatus',
  {
    method: 'POST',
    body: JSON.stringify({
      name,
      surname,
      birthDate,
      birthPlace
    })
  }
)

export const getLandCodes = () => fetchFromAPI('app/api/landcodes')

export const getBlockReasons = () => fetchFromAPI('app/api/blockreasons')

export const blockPlayer = (body) => fetchFromAPI(
  'app/api/sperren',
  {
    method: 'POST',
    body: JSON.stringify(body)
  }
)

export const countScan = (body) => fetchFromAPI(
  'app/api/scan',
  {
    method: 'POST',
    body: JSON.stringify(body)
  }
)

export const getSocialConcept = async (contentType = 'application/pdf') => {
  const response = await fetch(`${rootURL}/app/api/socialConcept`, {
    headers: {
      ...createDefaultHeaders(),
      'Accept': contentType
    }
  })

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error('No social concept available for this shop')
    }
    throw new Error(`Failed to fetch social concept: ${response.statusText}`)
  }

  // Get the raw binary data instead of parsing as JSON
  return await response.arrayBuffer()
}
