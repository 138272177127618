import { Grid } from '@material-ui/core'
import React, { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Routes, useNavigate, Route, useLocation } from 'react-router-dom'
import { blockPlayer, getBlockReasons, getLandCodes } from '../../services/api'
import { BLOCK_SUCCESS_CODE, lockType, scanCases, stateReducer } from '../consts'
import { formatDate } from '../helpers'
import Status from '../Status'
import BlockWizard from './BlockWizard'
import FinalAcceptance from './FinalAcceptance'
import IdBack from './IdBack'
import IdFront from './IdFront'
import Lock from './Lock'
import Reason from './Reason'
import Transmission from './Transmission'
import logger from '../../logger'
import OCRView from '../OCR/OCRView'
import ProviderOCR from '../providers/ProviderOCR'

const steps = [
  { id: 'ocr', href: 'ocr' },
  { id: 'idFront', href: 'idFront' },
  { id: 'idBack', href: 'idBack' },
  { id: 'lockScreen', href: 'lockScreen' },
  { id: 'reason', href: 'reason' },
  { id: 'final', href: 'final' },
  { id: 'transmission', href: 'transmission' }
]

const PlayerBlock = () => {
  const { t } = useTranslation('oasisApp')
  const [state, dispatch] = useReducer(stateReducer, { steps, status: {} })
  const [stepsDone, setStepsDone] = useState([])
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    getLandCodes().then(({ items }) => {
      dispatch({ landCodes: items })
    })
      .then(getBlockReasons).then(({ items }) => dispatch({ blockReasons: items?.filter(item => item.kbez1 !== '10') }))
  }, [])

  const setStepStatus = (stepId) => setStepsDone([...new Set([...stepsDone, stepId])])

  const onResults = (results, currentStep, nextStep) => {
    logger.debug(`Moved to: ${nextStep}, from: ${currentStep}`)
    dispatch({ [currentStep]: results })
    setStepStatus(currentStep)
    navigate(nextStep)
  }

  const onTransmission = () => {
    const { name, surname, landId, birthPlace, birthName, birthDate } = state['idFront']
    const { plz, wohnort, street, houseNumber, additionalAddress } = state['idBack']
    const { blockTo, reasonId } = state['reason']

    blockPlayer({
      blockReasonId: reasonId,
      name,
      surname,
      landId,
      birthPlace,
      birthName,
      birthDate: formatDate(birthDate),
      plz,
      wohnort,
      street,
      houseNumber,
      additionalAddress,
      signature: state.final.signatureImage,
      blockTo: formatDate(blockTo),
    })
      .then(response => {
        logger.info(response)
        dispatch({ status: response })
        navigate('status')
      })
      .catch(error => logger.error(error))
  }

  const unsuccessfullyBlock = state.status.statusCode !== BLOCK_SUCCESS_CODE

  const currentStep = () => {
    const found = steps.find(({ href }) => location.pathname.includes(href))
    return found ? found.id : ''
  }

  const onOcrResults = (results) => onResults(results, 'ocr', 'idFront')

  return <Grid
    container
    direction='column'
    justifyContent='center'
    alignItems='center'
    spacing={1}
  >
    <ProviderOCR>
      <BlockWizard
        currentId={currentStep()}
        steps={steps}
        stepsDone={stepsDone}
      />
      <Routes>
        <Route
          path='ocr' element={
          <OCRView
            onResults={onOcrResults}
            href={'idFront'}
            scanCase={scanCases.BlockPlayerOneSide}
          />}
        />
        <Route
          path='idFront' element={
          <IdFront
            {...state.ocr}
            landCodes={state.landCodes}
            label={t('playerBlock.idFrontLabel')}
            onResults={(results) => onResults(results, 'idFront', 'idBack')}
          />}
        />
        <Route
          path='idBack' element={
          <IdBack
            {...state.ocr}
            label={t('playerBlock.idBackLabel')}
            onResults={(results) => onResults(results, 'idBack', 'lockScreen')}
          />}
        />
        <Route
          path='lockScreen' element={
          <Lock
            label={t('playerBlock.lockScreen')}
            onResults={(results) => onResults(results, 'lockScreen', 'reason')}
          />}
        />
        <Route
          path='reason' element={
          <Reason
            blockReasons={state.blockReasons}
            isSelfLock={state.lockScreen?.selectedLock === lockType.self}
            label={t('playerBlock.reasonComponentLabel')}
            onResults={(results) => onResults(results, 'reason', 'final')}
          />}
        />
        <Route
          path='final' element={
          <FinalAcceptance
            label={t('playerBlock.finalAcceptanceComponentLabel')}
            onResults={(results) => onResults(results, 'final', 'transmission')}
          />}
        />
        <Route
          path='transmission' element={
          <Transmission
            //TODO: sprawdzić tłumaczenie
            label={t('playerBlock.transmissionComponentLabel')}
            disabled={stepsDone.length !== steps.length - 1}
            onTransmission={onTransmission}
          />}
        />
        <Route
          path='status' element={
          <Status
            message={unsuccessfullyBlock
              ? state.status.message
              : `${state.status.message} ${t('playerBlock.lockID')}: ${state.status.lockId}`}
            statusCode={state.status.statusCode}
          />}
        />
      </Routes>
    </ProviderOCR>
  </Grid>
}

export default PlayerBlock
