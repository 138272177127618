import { addYears, formatISO, isAfter } from 'date-fns'
import { isEmpty } from 'lodash'
import {
  BLOCK_SUCCESS_CODE,
  BLOCKED_STATUS_CODE,
  COMPATIBILITY_ERROR_CODE,
  MORE_THAN_ONE_BAN_STATUS_CODE,
  OASIS_STATUS,
  UNBLOCKED_STATUS_CODE
} from './consts'

export const getCurrentStatus = (statusCode) => {
  switch (statusCode) {
    case BLOCKED_STATUS_CODE:
    case MORE_THAN_ONE_BAN_STATUS_CODE:
      return OASIS_STATUS.BLOCKED
    case UNBLOCKED_STATUS_CODE:
      return OASIS_STATUS.UNBLOCKED
    case BLOCK_SUCCESS_CODE:
      return OASIS_STATUS.BLOCKED_SUCCESSFULLY
    case COMPATIBILITY_ERROR_CODE:
      return OASIS_STATUS.ERROR
    default:
      return OASIS_STATUS.INFO
  }
}

export const getStatusColor = (defaultColor) => ({ status }) => {
  switch (status) {
    case OASIS_STATUS.BLOCKED:
      return '#F06957'
    case OASIS_STATUS.UNBLOCKED:
      return '#3EB55F'
    case  OASIS_STATUS.BLOCKED_SUCCESSFULLY:
      return '#3EB55F'
    case   OASIS_STATUS.ERROR:
      return '#F06957'
    default:
      return defaultColor
  }
}

export const errorExists = (errors) => !isEmpty(errors)

export const formatDate = (date) => formatISO(date, { representation: 'date' })

export const validateAge = (birthDate, years = 18) => isAfter(new Date(), addYears(birthDate, years))
